import { ButtonHTMLAttributes, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  BUTTON_SIZE,
  BUTTON_SIZE_CLASSNAME,
  BUTTON_VARIANT,
  BUTTON_VARIANT_CLASSNAME,
} from './buttonStyles';

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: BUTTON_VARIANT;
  size?: BUTTON_SIZE;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  leftIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      children,
      variant = BUTTON_VARIANT.PRIMARY,
      size = BUTTON_SIZE.BIG,
      disabled,
      isLoading,
      className,
      icon: Icon,
      leftIcon: LeftIcon,
      iconClassName,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={twMerge(
          'label-small transitionColor flex items-center justify-center whitespace-nowrap font-bold outline-none focus:outline-none disabled:pointer-events-none disabled:cursor-none disabled:transition-none',
          BUTTON_VARIANT_CLASSNAME[variant],
          BUTTON_SIZE_CLASSNAME[size],
          className
        )}
        disabled={disabled || isLoading}
        {...rest}
      >
        {LeftIcon && <LeftIcon className={twMerge('', iconClassName)} />}{' '}
        {children}
        {Icon && !LeftIcon && <Icon className={twMerge('', iconClassName)} />}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
